import React from 'react';

const InputPassword = ({ label, name, value, placeholder, handleChange }) => (
    <>
        <div className="form-group">
            {label && <label for="inputEmail4">{label}</label>}
            <input 
                type="password" 
                name={name} 
                value={value}
                onChange={handleChange} 
                placeholder={placeholder} 
                className="form-control" 
            />
        </div>
    </>
);

export default InputPassword;