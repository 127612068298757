import React from 'react';

const InputTextNumber = ({ label, name, value, placeholder, handleChange }) => (
    <>
        {label && <label for="inputEmail4">{label}</label>}
        <div className="form-group">
            <input 
                type="number" 
                name={name} 
                value={value}
                onChange={handleChange}
                placeholder={placeholder} 
                className="form-control" 
                min={0}
                //required 
            />
        </div>
    </>
);

export default InputTextNumber;