import React from 'react';
import { Link } from 'react-router-dom';
import menu from '../../config/menuList';
import { useAuth } from "../../hooks";

function Sidebar() {
	const { getUser } = useAuth();
	let { role } = getUser();
	return (
		<>
			<div className="overlay" />
			<div className="search-overlay" />
				<div className="sidebar-wrapper sidebar-theme">
					<nav id="sidebar">
						<div className="shadow-bottom" />
						<ul className="list-unstyled menu-categories" id="accordionExample">
							{menu.map((obj, index) => {
								if(obj.visibility && obj.visibility != role)
									return;
								else
									return (
										<li className="menu">
											<Link className="dropdown-toggle" to={obj.route}>
												<div className>
													{obj.icon}
													<span>{obj.name}</span>
												</div>
											</Link>
										</li>
									)
							})} 
						</ul>
					</nav>
				</div>
		</>
	);
}
export default Sidebar;