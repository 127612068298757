import React from 'react';

function Footer() {
  return (
   <>
    <div className="footer-wrapper">
        <div className="footer-section f-section-1">
          <p className>Copyright © 2021 <a target="_blank" href="http://checkadriver.com">CheckaDriver.com</a>. All rights reserved.</p>
        </div>
        <div className="footer-section f-section-2">
        </div>
    </div>
   </>
  );
}
export default Footer;