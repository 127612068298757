import React from 'react';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import Content from './content';
import BreadCrumb from './breadcrumb';

function Layout() {
  return (
    <>
        <Header />
        <div class="main-container" id="container">
          <Sidebar />
          <BreadCrumb />
          <div id="content" class="main-content">
            <div className="mt-4 ml-4">
              <Content />
            </div>
            <Footer />
          </div>
        </div>
    </>
  );
}
export default Layout;