import React from 'react';

const InputSearch = ({ name, handleChange, placeholder = "Search..." }) => (
    <>
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center mt-2">
            <div className="form-inline my-2 my-lg-0">
                <input onChange={ e => handleChange(e.target.value) } type="text" className="form-control product-search" id="input-search" placeholder={placeholder} />
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search ml-1"><circle cx={11} cy={11} r={8} /><line x1={21} y1={21} x2="16.65" y2="16.65" /></svg>
            </div>
        </div>
    </>
);

export default InputSearch;