import { useContext } from 'react';
import jwtDecode from "jwt-decode";
import AuthContext from '../context/AuthContext';

const useAuth = () => {
    const { user, setUser } = useContext(AuthContext);

    const logIn = token => {
        const userData = jwtDecode(token);
        setUser(userData);
        localStorage.setItem("token", token);
    };

    const logOut = () => {
        setUser(null);
        localStorage.removeItem("token");
    };

    const getUser = () => {
        const token = localStorage.getItem("token");
        return token ? jwtDecode(token) : null
    };

    return { user, getUser, logIn, logOut };
};

export default useAuth;