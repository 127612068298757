import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/layout';
import PrivateRoute from './components/layout/privateRoute';
import { ToastContainer, toast } from 'react-toastify';
import AuthContext from './context/AuthContext';

const loading = (
  <div id="load_screen"> 
    <div class="loader"> 
      <div class="loader-content">
        <div class="spinner-grow align-self-center"></div>
      </div>
    </div>
  </div>
)

// Public Pages
const Login = React.lazy(() => import('./components/auth/login'));

function App() {
  const [user, setUser] = useState();

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <React.Suspense fallback={loading}>
          <ToastContainer />
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <PrivateRoute path="/" name="Home" render={props => <Layout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    </AuthContext.Provider>
  );
}
export default App;