import { useState } from "react";
import { toast } from "react-toastify";

const useApi = (apiFunc) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const request = async (...args) => {
        try{
            setLoading(true);
            const res = await apiFunc(...args);
            setLoading(false);
            setData(res.data);
            return res.data;
        }
        catch (error) {
            setError(true);
            setLoading(false);
            error.response ? toast.error(error.response.statusText) : toast.error("Sorry, Something went wrong.");
        }
    };

    return { data, error, loading, request };
};

export default useApi;