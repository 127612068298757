import React from 'react'
import { Redirect } from 'react-router-dom'

const PrivateRoute = ({ render }) => {

    const Component = render;
    const isAuthenticated = localStorage.getItem('token');

    return isAuthenticated ? (
        <Component />
    ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
}

export default PrivateRoute;