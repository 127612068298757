import React, { Suspense } from 'react';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

import routes from '../../config/routes';
import { DefaultLoader } from '../common/loader';

function Content() {
  return (
    <>
        <Suspense fallback={<DefaultLoader />}>
            <Switch>
                {routes.map((route, idx) => {
                    return route.component && (
                        <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={props => (
                                <route.component {...props} />
                            )} />
                    )
                })}
                <Redirect from="/" to="/dashboard" />
            </Switch>
        </Suspense>
    </>
  );
}
export default Content;