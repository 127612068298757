import React from 'react';
import Loader from 'react-loader-spinner'

const DefaultLoader = () => (
    <>
        <div id="load_screen"> 
            <div class="loader"> 
                <div class="loader-content">
                    <div class="spinner-grow align-self-center"></div>
                </div>
            </div>
        </div>
    </>
);

const Spinner = () => (
    <>
        <center>
            <div style={{ zIndex: "100" }}>
                <Loader type="Oval" color="#6A776C" height="60" width="40" />
            </div>
        </center>
    </>
);

export { DefaultLoader, Spinner };