import axios from "axios";
import { toast } from "react-toastify";

//-------- Creating an Instance for Axios -------------
var axiosInstance = axios.create({
    //baseURL: 'http://localhost/dpms/dpms-backend/public',
    baseURL: 'https://checkadriver.com/backend/public',
    /* other custom settings */
});
// ------------------------------------------------

//----------- Axios request interceptor -----------
axiosInstance.interceptors.request.use(function (config) {
    const token = "Bearer " + localStorage.getItem("token");
    config.headers.Authorization = token;
    return config;
});
// ------------------------------------------------

//-------- Axios response interceptor -------------
axiosInstance.interceptors.response.use(null, error => {

    let errorMsg = "";
    switch (error.response.status) {
        case 400:
            errorMsg = error.response.data.error;
            break;
        case 403:
            errorMsg = "You are not authorized to view this page.";
            break;
        case 401:
            errorMsg = "Your request was made with invalid credentials or Token expired.";
            window.location.href = "logout";
            break;
        case 404:
            errorMsg = "Requested path not found.";
            break;
        default:
            return Promise.reject(error);
    }
    toast.error(errorMsg);

});
// -----------------------------------------------------------------------------

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete
};