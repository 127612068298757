import React from 'react';

const InputText = ({ label, name, value, placeholder, handleChange, type="normal" }) => (
    <>
        {type === "report" ? 

            <div class="form-group row">
            <label for="staticEmail" class="col-sm-4 col-form-label">{label}</label>
                <div class="col-sm-8">
                    <input 
                        type="text" 
                        name={name} 
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder} 
                        className="form-control" 
                        //required 
                    />
                </div>
            </div>
            :
            <div className="form-group">
                {label && <label for="inputEmail4">{label}</label>}
                <input 
                    type="text" 
                    name={name} 
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder} 
                    className="form-control" 
                    //required 
                />
            </div>
        }
    </>
);

export default InputText;