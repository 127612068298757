import React from 'react';

const Dashboard = React.lazy(() => import('../components/dashboard'));
// Importing Client Components
const ClientsList = React.lazy(() => import('../components/clients/listClients'));
const CreateClient = React.lazy(() => import('../components/clients/createClient'));
const ClientProfile = React.lazy(() => import('../components/clients/clientProfile'));

// Importing Driver Components
const DriversList = React.lazy(() => import('../components/drivers/listDrivers'));
const CreateDriver = React.lazy(() => import('../components/drivers/createDriver'));
const UploadDriver = React.lazy(() => import('../components/drivers/uploadDrivers'));
const SearchDrivers = React.lazy(() => import('../components/drivers/searchDrivers'));
const DriverProfile = React.lazy(() => import('../components/drivers/driverProfile'));
const DriverReport = React.lazy(() => import('../components/drivers/driverReport'));
const DeallocateDriver = React.lazy(() => import('../components/drivers/deallocateDrivers'));

const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },

    { path: '/clients', exact: true, name: 'Clients', component: ClientsList },
    { path: '/clients/create', exact: true, name: 'Create Client', component: CreateClient },
    { path: '/clients/:clientUid/update', exact: true, name: 'Update Client', component: CreateClient },
    { path: '/clients/:clientUid/profile', exact: true, name: 'Client Profile', component: ClientProfile },
    { path: '/clients/:clientUid/drivers', exact: true, name: 'Drivers of client', component: DriversList },
    
    { path: '/drivers', exact: true, name: 'Drivers', component: DriversList },
    { path: '/unallocated-drivers', exact: true, name: 'Unallocated Drivers', component: DriversList },
    { path: '/drivers/create', exact: true, name: 'Create Client', component: CreateDriver },
    { path: '/drivers/upload', exact: true, name: 'Upload Drivers', component: UploadDriver },
    { path: '/drivers/:driverUid/update', exact: true, name: 'Update Driver', component: CreateDriver },
    { path: '/drivers/:driverUid/profile', exact: true, name: 'Driver Profile', component: DriverProfile },
    { path: '/drivers/search', exact: true, name: 'Search Driver', component: SearchDrivers },
    { path: '/drivers/report', exact: true, name: 'Driver Report', component: DriverReport },
    { path: '/drivers/deallocate', exact: true, name: 'Deallocate Driver', component: DeallocateDriver },
];

export default routes;