import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import Modal from 'react-bootstrap/Modal';
import PasswordReset from '../auth/resetPassword';
import { useHistory } from 'react-router-dom'

function Header() {

  let history = useHistory();
  // Custom hooks for Authentication and API Services
  const { logOut } = useAuth();
  const [passModal, setPassModal] = useState(false);

  const handleModalClose = () => { 
    setPassModal(false); 
}

  return (
    <>
      {/*--------------------Reset Password Modal----------------------*/}
      <Modal show={passModal} onHide={handleModalClose}>
        <PasswordReset handleModalClose={e => handleModalClose()} />
      </Modal>
      {/*---------------------------------------------------------------*/}
      <div className="header-container fixed-top">
        <header className="header navbar navbar-expand-sm">
          <ul className="navbar-item theme-brand flex-row  text-center">
            <li className="nav-item theme-logo"></li>
            <li className="nav-item theme-text">
              {/*<img  className="nav-link" style={{ width: "4%" }} src="../assets/img/login.jpg" alt="login"/>*/}
              <a href="http://checkadriver.com" className="nav-link"> Check A Driver </a>
            </li>
          </ul>
          <ul className="navbar-item flex-row ml-md-auto">
            {/*<li className="nav-item dropdown notification-dropdown">
              <a href="javascript:void(0);" className="nav-link dropdown-toggle" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                  <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                </svg>
                <span className="badge badge-success" />
              </a>
              <div className="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
                <div className="notification-scroll">
                  <div className="dropdown-item">
                    <div className="media">
                      <div className="media-body">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>*/}
            <li className="nav-item dropdown user-profile-dropdown">
              <a href="javascript:void(0);" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <img src="/assets/img/user.png" alt="avatar" />
              </a>
              <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                <div className>
                  <div className="dropdown-item">
                    <a style={{cursor: "pointer"}} onClick={e=>setPassModal(true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock">
                        <rect x={3} y={11} width={18} height={11} rx={2} ry={2} />
                        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
                      </svg>
                      Change Password
                    </a>
                  </div>
                  <div className="dropdown-item">
                    <a style={{cursor: "pointer"}} onClick={e=> logOut()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </header>
      </div>
    </>
  );
}
export default Header;