import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import Joi from "joi-browser";

const useForm = (schema, callBackFunc) => {
    const [values, setValue] = useState({});
    const [errors, setErrors] = useState({});
   
    //--------------------Form Submission-------------------//
    const handleSubmit = e => {
        e.preventDefault();
        const errors = validate();
        setErrors({ errors: errors || {} });
        if (errors) return;
        callBackFunc();
    }
    //--------------------------------------------------------------//

    //---------------------Form Input Change----------------------//
    const handleChange = e => setValue({ ...values, [e.target.name]: e.target.value });
    //--------------------------------------------------------------//

    //---------------------Handle Form Validation-------------------//
    const validate = () => {
        const options = { abortEarly: true };
        const { error } = Joi.validate(values, schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
            toast.error(item.message);
        }
        return errors;
    };
    //--------------------------------------------------------------//

    return {
        handleChange,
        handleSubmit,
        values,
        setValue,
        errors,
    }
}
export default useForm;