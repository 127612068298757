import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from "../../../hooks";

const AddUserButton = ({ to }) => {
    const { getUser } = useAuth();
    let { role } = getUser();

    return(
        <>
            {role !== "Client" && (
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-5 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center"></div>
                    <Link to={to}>
                        <svg id="btn-add-contact" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>
                    </Link>
                </div>
            )}
        </>
    )
};

export default AddUserButton;