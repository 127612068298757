import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const InputSelect = ({ label, name, type = null, position="normal", value = null, options=[], placeholder="Select...", handleChange }) => {

    const statusList = [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        /*{ label: "Terminated", value: "Terminated" }*/
    ];
    const defaultValue = [{ label: placeholder, value: null }];
    const [selectedOption, setSelectedOption] = useState( defaultValue );

    const handleSelect = ({ value }) =>
    {
        let event = { target: { name, value } };
        handleChange(event);
    }

    useEffect(() => {
        if(value !== null)
            setSelectedOption(
                type === "status" ? statusList.find( item => item.value === value ) : options.find( item => item.value === value )
            );
        else
            setSelectedOption(defaultValue);
    }, [value]);

    return(
        <>
            {position === "normal" ? 
                <>
                <label for="inputEmail4">{label}</label>
                    <div className="form-group" style={{width: '200px', marginBottom: "0rem"}}>
                        <Select
                            name={name}
                            value={selectedOption}
                            onChange={handleSelect}
                            options={type === "status" ? statusList : options}
                        />
                    </div>
                </>
            :
            <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label">{label}</label>
                    <div class="col-sm-8">
                        <Select
                            name={name}
                            value={selectedOption}
                            onChange={handleSelect}
                            options={type === "status" ? statusList : options}
                        />
                    </div>
            </div>  
            }            
        </>
    )
};

export default InputSelect;