import React from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateTimePicker({ label, onSelectDate, name = "date", selectedDate = "", dateFormat = "YYYY-MM-DD" }) {

    if (selectedDate == "")
        selectedDate = new Date();

    const handleDateChange = date => {
        onSelectDate(
            moment(date)
            .format(dateFormat)
            .toString()
        );
    };

    return (
        <>
            {label && <label for="inputEmail4">{label}</label>}
            <div className="form-group">
                <DatePicker
                    name={name}
                    onChange={handleDateChange}
                    selected={
                        typeof selectedDate !== "object"
                            ? new Date(Date.parse(selectedDate))
                            : selectedDate
                    }
                    className="form-control input-sm splus-datepicker"
                    dateFormat={"d/M/y"}
                />
            </div>
        </>
    )
}