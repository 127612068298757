import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { DefaultLoader } from '../common/loader';
import authService from "../../services/authService";

import {
    InputPassword,
} from "../common/inputs";
import { useApi, useForm } from "../../hooks";

function ResetPassword({ handleModalClose }) {

    const authApi = useApi(authService.resetPassword);

    // Form Validation Schema
    const schema = Joi.object().keys({
        current_password: Joi.string().required().label("Current Password"),
        password: Joi.string().min(3).max(15).required().label("New Password"),
        password_confirmation: Joi.any().valid(Joi.ref('password')).required().label("Confirm Password").options({ language: { any: { allowOnly: 'must match password' } } }),
    });

    // Reset Password
    const resetPassword = async () => {
        const res = await authApi.request({ current_password : values.current_password, password: values.password });
        if (!res.success) toast.error(res.message);
        else {
          toast.success(res.message);
          handleModalClose();
        }
    }

    const {handleSubmit, handleChange, values} = useForm(schema, resetPassword);

    return (
        <>
            {authApi.loading === true && (
                <DefaultLoader />
            )}
            <form onSubmit={handleSubmit} className="text-left">
                <Modal.Header closeButton>
                    <Modal.Title>{'Reset Password'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <InputPassword name="current_password" value={values.current_password} handleChange={handleChange} placeholder="Current Password" /> <br />
                    <InputPassword name="password" value={values.password} handleChange={handleChange} placeholder="New Password" /> <br />
                    <InputPassword name="password_confirmation" value={values.password_confirmation} handleChange={handleChange} placeholder="Confirm Password" />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary">
                        {'Reset Password'}
                    </Button>
                    <Button variant="secondary" onClick={e => handleModalClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </form>
        </>
    );
}
export default ResetPassword;