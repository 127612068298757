import React from 'react';

const InputButton = ({ name, type="button", right="false", color="primary", outlined=false, text="Submit", handleClick }) => (
    <>
        <div className="form-group">
            <input 
                name={name}
                type={type} 
                value={text} 
                class={outlined === true ? "btn btn-outline-primary" : `btn btn-${color} ${right === true ? `float-right` : ''}`}
                onClick={handleClick}
            />
        </div>
    </>
);

export default InputButton;